<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 공포기간 -->
          <c-datepicker
            :range="true"
            defaultStart="-2y"
            defaultEnd="2M"
            label="LBL0010103"
            name="promulgationPeriod"
            v-model="searchParam.promulgationPeriod"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        </div>
      </template>
    </c-search-box>
    <!--법령 목록-->
    <c-table
      title="LBL0010096"
      tableId="safetyControlDangerous"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!--검색-->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="props.row.lawLink">
          <q-btn
            class="tableinnerBtn"
            flat
            icon="launch"
            align="center"
            color="blue-6"
            label=""
            @click.stop="linkClick(props, col)" />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safety-control-dangerous',
  data() {
    return {
      searchParam: {
        promulgationPeriod: [],
      },
      grid: {
        columns: [
          {
            name: 'lawSerialNum',
            field: 'lawSerialNum',
            //법령일련번호
            label: 'LBL0010097',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'curHistroyCode',
            field: 'curHistroyCode',
            //현행연혁코드
            label: 'LBL0010098',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'lawNameKor',
            field: 'lawNameKor',
            //법령명한글
            label: 'LBL0010079',
            align: 'center',
            sortable: false,
            style: 'width:120px',
          },
          {
            name: 'lawNameAbb',
            field: 'lawNameAbb',
            //법령약칭명
            label: 'LBL0010080',
            align: 'center',
            sortable: false,
            style: 'width:100px',
          },
          {
            name: 'promulgationDate',
            field: 'promulgationDate',
            //공포일자
            label: 'LBL0010081',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'revisiontypename',
            field: 'revisiontypename',
            //제개정구분명
            label: 'LBL0010099',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'competentMinistriesName',
            field: 'competentMinistriesName',
            //소관부처명
            label: 'LBL0010100',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'lawClassName',
            field: 'lawClassName',
            //법령구분명
            label: 'LBL0010101',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'enforcementDate',
            field: 'enforcementDate',
            //시행일자
            label: 'LBL0010085',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'lawTypeFlag',
            field: 'lawTypeFlag',
            //자법타법여부
            label: 'LBL0010102',
            align: 'center',
            sortable: false,
            style: 'width:80px',
          },
          {
            name: 'custom',
            field: 'custom',
            //법령상세링크
            label: 'LBL0010086',
            align: 'center',
            sortable: false,
            style: 'width:80px',
            type: 'custom'
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.law.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(props, col) {
      window.open('http://www.law.go.kr' + props.row.lawLink, '법규', 'width=1200, height=700');
    }
  }
};
</script>
